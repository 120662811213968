import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/plugins/vue-i18n'

Vue.use(VueRouter)
let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
const supportedLocales = i18n.locales.reduce((acc, cur) => {
  acc[cur.code] = cur.route
  return acc
}, {})

function shouldFlatRoute (route) {
  return route.path && route.children && !route.component
}

function applyPrefix (prefix, routes) {
  return routes.map(route => {
    route.path = prefix + '/' + route.path
    return route
  })
}

function flatRoutes (routes) {
  const flattenedRoutes = []

  for (const route of routes) {
    if (!route.children || route.children.length === 0) {
      flattenedRoutes.push(route)
    } else {
      if (shouldFlatRoute(route)) {
        // Flat the logical nesting and call recursion
        flattenedRoutes.push(
          ...flatRoutes(applyPrefix(route.path, route.children))
        )
      } else {
        // Keep the nesting level but call recursion on children
        route.children = flatRoutes(route.children)
        flattenedRoutes.push(route)
      }
    }
  }

  return flattenedRoutes
}

const routes = [
  {
    path: '/',
    redirect: `/${ supportedLocales[i18n.locale] }`
  },
  {
    path: '/:locale',
    base: (locale.trim().length && locale !== '/') ? '/' + locale : undefined,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: () => import('@/views/HomePage')
      },
      {
        path: 'tools',
        name: 'ToolsPage',
        component: () => import('@/views/ToolsPage')
      },
      {
        path: 'demo',
        name: 'DemoPage',
        component: () => import('@/views/DemoPage')
      },
      {
        path: 'policy',
        name: 'PolicyPage',
        component: () => import('@/views/PolicyPage')
      },
      {
        path: 'terms',
        name: 'TermsPage',
        component: () => import('@/views/TermsPage')
      },
      {
        path: 'return',
        name: 'ReturnPage',
        component: () => import('@/views/ReturnPage')
      },
      // {
      //   path: 'prices',
      //   name: 'PricesPage',
      //   component: () => import('@/views/PricesPage')
      // },
      {
        path: 'contact',
        name: 'ContactPage',
        component: () => import('@/views/ContactPage')
      },
      {
        path: 'blog',
        name: 'BlogPage',
        component: () => import('@/views/Blog/PageBlog.vue')
      },
      {
        path: 'blog/:id',
        name: 'BlogInfo',
        component: () => import('@/views/Blog/InfoBlog.vue')
      },
      {
        path: '404',
        name: 'not-found',
        meta: {
          public: true,
          access: 'public'
        },
        component: () => import('@/views/ErrorPage')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes: flatRoutes(routes)
})
router.beforeEach((to, from, next) => {
  let language = to.params.locale
  if (!i18n.availableLocales.some(i => supportedLocales[i] === language)) {
    next(`${ supportedLocales[i18n.locale] }/404`)
    // next('/en/404')
    return
  }
  // console.log(language)
  // to.params.locale = language
  next()
})
export default router
