function serializeData (key, value) {
  return {
    property: key,
    vmid: key,
    name: key,
    content: value
  }
}

export default {
  install (Vue) {
    Vue.prototype.$metaDefault = function (_meta = {}, _title = '') {
      const meta = [
        serializeData('og:url', window.location.origin + this.$route.path),
        serializeData('og:site_name', 'EasyPON'),
        ...this.$i18n.locales.map(locale => {
          let name = 'og:locale'
          if (locale.code !== this.$i18n.locale) name += `:alternate`
          const content = locale.iso
          return serializeData(name, content)
        })
      ]

      const name = this.$route.name
      const title = _title || this.$t(`meta.${ name }.title`) === '-' ? 'EasyPON' : this.$t(`meta.${ name }.title`)
      Object.keys(_meta).forEach(key => {
        meta.push(
          serializeData(key, _meta[key])
        )
      })
      const metaKeys = ['description', 'og:title', 'og:description']

      metaKeys.forEach(key => {
        if (_meta.key) return

        if (this.$t(`meta.${ name }.${ key }`) !== '-')
          meta.push(
            serializeData(key, this.$t(`meta.${ name }.${ key }`))
          )
      })

      if (!_meta['og:image']) {
        meta.push(
          serializeData('og:image', window.location.origin + '/ogImage.png')
        )
      }

      return {
        title,
        meta
      }
    }
  }
}