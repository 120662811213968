<template lang="pug">
  img(
    :src="Chevron"
    alt="chevron"
  )
</template>

<script>
import Chevron from '@/assets/images/chevron.svg'

export default {
  name: "ChevronDown",
  data () {
    return {
      Chevron
    }
  }
}
</script>
