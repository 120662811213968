import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ChevronDown from '@/components/Icons/ChevronDown'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      chevronDown: {
        component: ChevronDown,
        props: {
          name: 'chevronDown'
        }
      }
    }
  }
});
