import Vue from 'vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import './assets/styles/style.sass'
import App from './App.vue'
import i18n from '@/plugins/vue-i18n'
import router from './router'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.common.js'
import 'vue-phone-number-input/dist/vue-phone-number-input.umd.js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { BootstrapVue } from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'
import metaDefault from '@/plugins/metaDefault'

// eslint-disable-next-line
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.use(metaDefault)
Vue.use(BootstrapVue)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 100,
  easing: 'linear',
  offset: -79,
  force: true,
  x: false
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
