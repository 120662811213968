<template lang="pug">
  v-app
    v-main
      header-block
      banner-block
      router-view.page-default
      footer-block
</template>

<script>
export default {
  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      title: 'EasyPON',
      meta: [
        // { charset: 'utf-8' },
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  },
  name: 'App',
  components: {
    FooterBlock: () => import('@/components/FooterBlock'),
    HeaderBlock: () => import('@/components/HeaderBlock'),
    BannerBlock: () => import('@/components/BannerBlock')
  },
  watch: {
    'userInfo.language': {
      handler (val) {
        if (val) this.$i18n.locale = val
      }
    }
  }
}
</script>
