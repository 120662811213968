import en from '@/configs/translations/en'
import uk from '@/configs/translations/uk'
import hr from '@/configs/translations/hr'

const fallbackLocale = 'en'
const availableLocales = [{
  code: 'en',
  route: 'en',
  iso: 'en-US',
  label: 'English',
  messages: en
}, {
  code: 'uk',
  route: 'ua',
  iso: 'uk-UA',
  label: 'Ukrainian',
  messages: uk
}, {
  code: 'hr',
  route: 'hr',
  iso: 'hr-HR',
  label: 'Croatian',
  messages: hr
}]

const localeRoute = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
const locale = availableLocales.find(i => i.route === localeRoute)?.code || localStorage.getItem('locale') || fallbackLocale

localStorage.setItem('locale', locale)
export default {
  locale,
  fallbackLocale,
  availableLocales
}
